import React, { useEffect } from "react";
import leftimg from "../../assets/Leftimg.png";
import Button from "../buttons";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const WhatWeDo = ({ value }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <div className="  lg:flex md:grid grid justify-center items-center lg:px-24 md:px-10 px-8 gap-10 mt-5">
        <div data-aos="zoom-in" className=" lg:w-[70%] md:w-full w-full">
          <p className=" text-[#4CAF50] text-lg font-Roboto font-medium ">
            What we do
          </p>
          <div className=" space-y-5 pt-3">
            <p className="text-stroke Satoshi lg:text-5xl md:text-5xl text-3xl text-primary font-black">
              Our Focus
            </p>
            <p className=" text-base font-light text-[#0F172A]">
              Our approach is grounded in the belief that real, lasting change
              comes from empowering communities to drive their own development.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className=" lg:grid-cols-2 md:grid-cols-2 grid-cols-1 grid lg:gap-5 md:gap-10 gap-10 lg:pt-5 md:pt-10 pt-10"
          >
            {value.map((v) => (
              <div className="grid lg:w-72 md:w-80 w-full gap-3 ">
                <div className=" grid gap-3">
                  <img className=" w-8 h-8" src={v.img} alt="" />
                  <p className=" font-extrabold text-[21px] Satoshi  tracking-wide Satoshi ">
                    {v.title}
                  </p>
                </div>
                <div className=" ">
                  <p className=" font-light text-[#0F172A] ">{v.desc}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="   flex items-center pt-10">
            {/* <Button
              onClick={() => navigate("/focus")}
              text="View all"
              css={
                "text-[#4CAF50] w-44 h-14 text-lg border border-[#4CAF50] rounded-full"
              }
            /> */}
          </div>
        </div>
        <div data-aos="zoom-out" className=" lg:w-[30%] md:w-full w-full">
          <img src={leftimg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
