import React from "react";
import Derek from "../../assets/DEREKFOODS.png";
import cepahsimg from "../../assets/cepahsimg.png";
import contmap from "../../assets/contmap.png";
import bsaimage from "../../assets/bsaimage.png";
import LiteArtlogo from "../../assets/LiteArtlogo.png";
import WhatsAppImage from "../../assets/WhatsAppImage.png";

const OurClients = () => {
  const clients = [
    {
      id: 1,
      img: LiteArtlogo,
      width: "200px",
      height: "70.85px",
    },

    {
      id: 2,
      img: WhatsAppImage,
      width: "200px",
      height: "115px",
    },
    {
      id: 3,
      img: Derek,
      width: "200px",
      height: "200px",
    },

    {
      id: 4,
      img: cepahsimg,
      width: "200px",
      height: "200px",
    },

    {
      id: 5,
      img: bsaimage,
      width: "200px",
      height: "140.59px",
    },
  ];
  return (
    <div className="marquee-container">
      {/* <div className="marquee h-full py-5 relative flex pt-10 w-full">
        {clients.map((items) => (
          <div key={items.id} className="marquee-item ">
            <img
              className=" lg:w-[182.17px] md:w-[182.17px] w-auto lg:h-[75.25px] md:h-[75.25px] h-auto "
              src={items.img}
              alt=""
            />
          </div>
        ))}
      </div> */}
      <div className="marquee flex items-center  gap-10">
        {clients.map((item) => (
          <img
            key={item.id}
            className="marquee-item"
            style={{ width: item.width, height: item.height }}
            src={item.img}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};

export default OurClients;
