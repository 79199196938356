import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/CEFCODEVLOGOtwo.jpg";
import Button from "../buttons";
import Aos from "aos";

const Navbar = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(false);

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };
  const closeTggle = () => {
    setIsToggle(false);
  };
  return (
    <div className="">
      <div className=" relative z-20 flex items-center justify-between lg:px-24 md:px-10 px-8 py-4 bg-white  ">
        <div>
          <img
            onClick={() => navigate("/home")}
            className=" w-28 h-16 cursor-pointer"
            src={Logo}
            alt=""
          />
        </div>
        <div className=" flex items-center  gap-20">
          <ul className=" lg:flex md:flex hidden items-center gap-6 ">
            <li
              onClick={() => navigate("/home")}
              className=" font-DMSans cursor-pointer text-base text-main"
            >
              Home
            </li>
            <li
              onClick={() => navigate("/about-us")}
              className=" font-DMSans text-base cursor-pointer text-[#0F172A]"
            >
              About Us
            </li>
            <li
              onClick={() => navigate("/events")}
              className=" font-DMSans cursor-pointer text-base text-[#0F172A]"
            >
              Events
            </li>
            <li
              onClick={() => navigate("/contact-us")}
              className="  font-DMSans cursor-pointer text-base text-[#0F172A]"
            >
              Contact Us
            </li>
            <li
              onClick={() => navigate("/our-partners")}
              className="  font-DMSans cursor-pointer text-base text-[#0F172A]"
            >
              Our Partners
            </li>
          </ul>
        </div>

        <div className="  lg:flex md:hidden hidden items-center gap-5">
          <Button
            onClick={() => navigate("/volunteer")}
            css={"text-base font-Roboto text-main"}
            text="Volunteer"
          />
          <Button
            onClick={() => navigate("/donate")}
            text="Donate"
            css={"bg-main w-28 h-12 rounded-xl font-roboto text-sm text-white"}
          />
        </div>
        <div className=" lg:hidden md:block block">
          <GiHamburgerMenu onClick={handleToggle} />
        </div>
        {isToggle && (
          <div
            data-aos="fade-right"
            className=" grid fixed z-30 top-24 md:right-0 left-0 bg-gradient-to-b from-[#8BCC8B] to-main md:w-full h-screen w-full p-5 md:space-y-0   "
          >
            <ul className=" lg:hidden md:hidden grid items-center justify-center gap-5">
              <li
                onClick={() => navigate("/home")}
                className=" Satoshi font-thin text-xl text-[#fff] text-center"
              >
                Home
              </li>
              <li
                onClick={() => navigate("/about-us")}
                className=" Satoshi font-thin text-xl text-[#fff] text-center"
              >
                About Us
              </li>
              <li
                onClick={() => navigate("/events")}
                className=" Satoshi font-thin text-xl text-[#fff] text-center"
              >
                Events
              </li>
              <li
                onClick={() => navigate("/contact-us")}
                className=" Satoshi font-thin text-xl text-[#fff] text-center"
              >
                Contact Us
              </li>
              <li
                onClick={() => navigate("/our-partners")}
                className=" Satoshi font-thin text-xl text-[#fff] text-center"
              >
                Our Partners
              </li>
            </ul>

            <div className="  flex gap-5 items-center justify-center">
              <Button
                onClick={() => navigate("/volunteer")}
                css={"text-base font-Roboto text-[#fff]"}
                text="Volunteer"
              />
              <Button
                onClick={() => navigate("/donate")}
                text="Donate"
                css={
                  "bg-main w-28 h-12 rounded-xl font-Roboto text-sm text-white"
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { Link, useNavigate } from "react-router-dom";
// import Logo from "../../assets/CEFCODEVLOGOtwo.jpg";
// import Button from "../buttons";

// const Navbar = () => {
//   const navigate = useNavigate();
//   const [isToggle, setIsToggle] = useState(false);
//   const [activeLink, setActiveLink] = useState("/home"); // Default active link is 'home'

//   const handleToggle = () => {
//     setIsToggle(!isToggle);
//   };

//   const handleNavigation = (path) => {
//     setActiveLink(path); // Set the active link
//     navigate(path); // Navigate to the clicked page
//   };

//   return (
//     <div className="border-main border-b">
//       <div className=" relative z-20 flex items-center justify-between lg:px-24 md:px-10 px-8 py-4 bg-white">
//         <div className=" flex items-center  gap-20">
//           <img
//             onClick={() => handleNavigation("/home")}
//             className="w-28 h-16 cursor-pointer"
//             src={Logo}
//             alt=""
//           />
//           <ul className="lg:flex md:flex hidden items-center gap-10">
//             <li
//               onClick={() => handleNavigation("/home")}
//               className={`font-DMSans cursor-pointer font-semibold text-sm ${
//                 activeLink === "/home" ? "text-[#5608D4]" : "text-[#000]"
//               }`}
//             >
//               Home
//             </li>
//             <li
//               onClick={() => handleNavigation("/about-us")}
//               className={`font-DMSans cursor-pointer font-semibold text-sm ${
//                 activeLink === "/about-us" ? "text-[#5608D4]" : "text-[#000]"
//               }`}
//             >
//               About Us
//             </li>
//             <li
//               onClick={() => handleNavigation("/events")}
//               className={`font-DMSans cursor-pointer font-semibold text-sm ${
//                 activeLink === "/events" ? "text-[#5608D4]" : "text-[#000]"
//               }`}
//             >
//               Events
//             </li>
//             <li
//               onClick={() => handleNavigation("/contact-us")}
//               className={`font-DMSans cursor-pointer font-semibold text-sm ${
//                 activeLink === "/contact-us" ? "text-[#5608D4]" : "text-[#000]"
//               }`}
//             >
//               Contact Us
//             </li>
//           </ul>
//         </div>

//         <div className="lg:flex md:hidden hidden items-center gap-5">
//           <Button
//             onClick={() => navigate("/volunteer")}
//             css={"text-base font-semibold font-Roboto text-main"}
//             text="Volunteer"
//           />
//           <Button
//             onClick={() => navigate("/donate")}
//             text="Donate"
//             css={
//               "bg-main w-28 h-12 font-semibold rounded-xl font-roboto text-sm text-white"
//             }
//           />
//         </div>
//         <div className="lg:hidden md:block block">
//           <GiHamburgerMenu onClick={handleToggle} />
//         </div>
//         {isToggle && (
//           <div className="grid absolute z-30 top-24 md:right-0 bg-gradient-to-b from-[#5608D4] to-[#384295] md:w-[350px] w-full p-5 md:space-y-0 space-y-10">
//             <ul className="lg:hidden md:hidden grid items-center gap-5">
//               <li
//                 onClick={() => handleNavigation("/home")}
//                 className={`Satoshi font-thin text-sm ${
//                   activeLink === "/home" ? "text-[#5608D4]" : "text-[#fff]"
//                 }`}
//               >
//                 Home
//               </li>
//               <li
//                 onClick={() => handleNavigation("/about-us")}
//                 className={`Satoshi font-thin text-sm ${
//                   activeLink === "/about-us" ? "text-[#5608D4]" : "text-[#fff]"
//                 }`}
//               >
//                 About Us
//               </li>
//               <li
//                 onClick={() => handleNavigation("/events")}
//                 className={`Satoshi font-thin text-sm ${
//                   activeLink === "/events" ? "text-[#5608D4]" : "text-[#fff]"
//                 }`}
//               >
//                 Events
//               </li>
//               <li
//                 onClick={() => handleNavigation("/contact-us")}
//                 className={`Satoshi font-thin text-sm ${
//                   activeLink === "/contact-us"
//                     ? "text-[#5608D4]"
//                     : "text-[#fff]"
//                 }`}
//               >
//                 Contact Us
//               </li>
//             </ul>

//             <div className="flex gap-5 items-center">
//               <Button
//                 onClick={() => navigate("/volunteer")}
//                 css={"text-base font-Roboto text-[#fff]"}
//                 text="Volunteer"
//               />
//               <Button
//                 onClick={() => navigate("/donate")}
//                 text="Donate"
//                 css={
//                   "bg-main w-28 h-12 rounded-xl font-Roboto text-sm text-white"
//                 }
//               />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;
